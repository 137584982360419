import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/tables.module.scss';
import InfoBox from '../components/InfoBox';
import Table from '../components/Table';
import * as utils from '../utils';
import { FormattedMessage } from 'react-intl';
import Spin from '../components/Spin';

const length = 10;
let noMore = true;
let offset = 0;
let account, dayIx;

const getTableData = async (tokenAddress, status) => {
    let _length = length;
    if (offset <= length) {
        _length = offset;
        offset = 0;
        noMore = true;
    } else {
        offset -= length;
        noMore = false;
    }
    let data = await utils.getMySeasonGames(
        tokenAddress,
        dayIx,
        status,
        offset,
        _length
    );
    return [...data].reverse();
};

const getMySeasonInfo = async (tokenAddress) => {
    const data = await utils.getMySeasonInfo(tokenAddress, dayIx);
    return {
        playingNum: data.totalPlayingGameNum,
        endNum:
            Number(data.totalBlockingGameNum) + Number(data.totalEndGameNum),
        hitNums: [...data.hitNums].sort((a, b) => a - b).join(' ')
    };
};

const Tables = (props) => {
    const currentToken = useSelector((state) => state?.currentOption?.token);
    const tokenAddress = currentToken.address;
    const [tableArr, setTableArr] = useState([]);
    const [mySeasonInfo, setMySeasonInfo] = useState({
        playingNum: 0,
        endNum: 0,
        hitNums: ''
    });
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('0');

    const init = async () => {
        const accounts = await utils.web3().eth.getAccounts();
        // const accounts = [constants.testAddr];
        account = accounts[0];
        dayIx = await utils.getCurrentDayIndex();
        setLoading(true);
        let myInfoData = await getMySeasonInfo(tokenAddress);
        setMySeasonInfo(myInfoData);
        offset = status === '0' ? myInfoData.playingNum : myInfoData.endNum;
        const tableData = await getTableData(tokenAddress, status);
        setTableArr(tableData);
        setLoading(false);
    };
    const handleChangeStatus = async (status) => {
        setLoading(true);
        offset = status === '1' ? mySeasonInfo.endNum : mySeasonInfo.playingNum;
        const data = await getTableData(tokenAddress, status);
        setStatus(status);
        setTableArr(data);
        setLoading(false);
    };
    const handleNextPage = async () => {
        setLoading(true);
        const data = await getTableData(tokenAddress, status, offset, length);
        setTableArr(tableArr.concat(data));
        setLoading(false);
    };
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={style.tables}>
            {loading ? (
                <div className='spin-bg'>
                    <Spin />
                </div>
            ) : null}
            <div className={style.header}>
                <span
                    onClick={() => {
                        props.history.go('-1');
                    }}>
                    返回首页
                </span>
                <FormattedMessage id='betted' />
            </div>
            <div className={style.tab}>
                <span
                    className={`${status === '1' ? style.active : ''}`}
                    onClick={() => handleChangeStatus('1')}>
                    <FormattedMessage id='completed' />
                    {` ${mySeasonInfo.endNum}`}
                </span>
                <span
                    className={`${status === '0' ? style.active : ''}`}
                    onClick={() => handleChangeStatus('0')}>
                    <FormattedMessage id='ongoing' />
                    {` ${mySeasonInfo.playingNum}`}
                </span>
            </div>
            <div className={style.numbers}>
                <InfoBox
                    heading={<FormattedMessage id='numbersHaveBeenHit' />}
                    number={
                        mySeasonInfo.hitNums === '' ? (
                            <FormattedMessage id='none' />
                        ) : (
                            mySeasonInfo.hitNums
                        )
                    }
                />
            </div>
            <div className={style.container}>
                {tableArr.length &&
                    tableArr.map((el, i) => {
                        return (
                            <div key={i} className={style.item}>
                                <Table
                                    data={el}
                                    isSmall={true}
                                    account={account}
                                    currentToken={currentToken}></Table>
                            </div>
                        );
                    })}
            </div>
            <div style={{ textAlign: 'center' }}>
                {noMore || loading ? null : (
                    <div className={style.loadMoreBtn} onClick={handleNextPage}>
                        <FormattedMessage id='loadMore' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tables;
