import { useState, useEffect } from 'react';
import style from '../style/home.module.scss';
import { serverLink } from '../store/constants';
import { message } from 'antd';
import { removeDecimal } from '../utils';
import { FormattedMessage } from 'react-intl';

const getTotalTop = async () => {
    let data;
    try {
        const res = await fetch(`${serverLink}/api/history-top-player`).then(
            (res) => res.json()
        );
        if (res.code === 0 && res.data) {
            data = {
                PlayerTotal: `${res.data.total.Player}`,
                PlayerSingle: `${res.data.one_day.Player}`,
                NumTotal: removeDecimal(res.data.total.Total, 0, 1),
                NumSingle: removeDecimal(res.data.one_day.Total, 0, 1)
            };
        } else {
            message.error(res.msg);
        }
    } catch (e) {
        message.error(e);
    }
    return data;
};

const Trophies = () => {
    const [totalInfo, setTotalInfo] = useState({
        PlayerTotal: '',
        PlayerSingle: '',
        NumTotal: 0,
        NumSingle: 0
    });
    useEffect(() => {
        const getData = async () => {
            const data = await getTotalTop();
            if (data) {
                setTotalInfo(data);
            }
        };
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={style.trophies}>
            <div className={style.title}>
                <FormattedMessage id='Beacon' />
            </div>
            <div className={style.total}>
                <span>
                    <FormattedMessage id='totalElixirKing' />
                </span>
                <span>{totalInfo.PlayerTotal}</span>
                <span>
                    {totalInfo.NumTotal}
                    <FormattedMessage id='elixir' />
                </span>
            </div>
            <div className={style.single}>
                <span>
                    <FormattedMessage id='singleElixirKing' />
                </span>
                <span>{totalInfo.PlayerSingle}</span>
                <span>
                    {totalInfo.NumSingle}
                    <FormattedMessage id='elixir' />
                </span>
            </div>
        </div>
    );
};

export default Trophies;
