import { useState, useEffect } from 'react';
import style from '../style/home.module.scss';
import { FormattedMessage } from 'react-intl';
import { getRemainingTime } from '../utils';

const Countdown = () => {
    const [remainingTime, setRemainingTime] = useState(0);
    let interval;
    const countdown = () => {
        interval = setInterval(() => {
            const remainingTime = getRemainingTime();
            setRemainingTime(remainingTime);
        }, 1000);
    };
    useEffect(() => {
        countdown();
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime]);
    return (
        <p className={style.countdown}>
            <>
                <FormattedMessage id='Countdown' />
                {':  '}
                {remainingTime}
            </>
        </p>
    );
};

export default Countdown;
