import React, { Component } from 'react';
import style from '../style/picknumber.module.scss';
import { connect } from 'react-redux';
import ERC20TokenABI from '../ERC20token_contact_abi.json';
import * as constants from '../store/constants';
import * as utils from '../utils';
import * as actionCreators from '../store/actionCreators';
import { Checkbox } from 'antd';
import SelectPopup from '../components/SelectPopup';
import Spin from '../components/Spin';
import { message } from 'antd';
import { FormattedMessage } from 'react-intl';
import Decimal from 'decimal.js';

class PickNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableNum: this.props.match.params.tableNum,
            nums: [],
            position: '',
            tableData: null,
            pickedNum: [],
            checkboxChecked: 1,
            showSelectPopup: false,
            loading: false
        };
        this.handleClickNumber = this.handleClickNumber.bind(this);
        this.sendPickedNum = this.sendPickedNum.bind(this);
        this.handlePickPosition = this.handlePickPosition.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.changeCurrentValue = this.changeCurrentValue.bind(this);
        this.changeTable = this.changeTable.bind(this);
    }
    async componentDidMount() {
        await this.getTableData(this.state.tableNum);
        const { currentOption } = this.props;
        const ERC20TokenABIIns = new (utils.web3().eth.Contract)(
            ERC20TokenABI,
            currentOption.token.address
        );
        const accounts = await utils.web3().eth.getAccounts();
        const account = accounts[0];
        let allowance = await ERC20TokenABIIns.methods
            .allowance(account, constants.contractAddr)
            .call();
        if (Number(allowance) < Number(currentOption.value)) {
            this.toggleLoading(true);
            try {
                await ERC20TokenABIIns.methods
                    .approve(
                        constants.contractAddr,
                        new Decimal(currentOption.value)
                            .mul(new Decimal(10).pow(5))
                            .toFixed()
                    ) //100000倍当前选项 token allowance
                    .send({ from: account });
                this.toggleLoading(false);
            } catch (e) {
                console.error(e);
                message.info('请先授权交易');
                this.toggleLoading(false);
                window.history.back(-1);
            }
        }
    }
    async getTableData(tableNum) {
        const { currentOption } = this.props;
        const tableData = await utils.getGame(
            currentOption.token.address,
            tableNum
        );
        const pickedNum = [];
        if (tableData) {
            for (let i = 0; i < 5; i++) {
                if (tableData.bettor[i] !== constants.blankAddr) {
                    pickedNum.push(tableData.betNum1[i]);
                    pickedNum.push(tableData.betNum2[i]);
                }
            }
        }
        this.setState({
            tableData,
            pickedNum
        });
    }
    handleClickNumber(num) {
        const { nums, pickedNum } = this.state;
        if (pickedNum.indexOf(num) > -1) {
            return;
        }
        const ixof = nums.indexOf(num);
        if (ixof === -1) {
            nums.push(num);
        } else {
            nums.splice(ixof, 1);
        }
        this.setState({
            nums
        });
        if (nums.length === 2) {
            if (nums[0] > nums[1]) {
                [nums[0], nums[1]] = [nums[1], nums[0]];
            }
            this.sendPickedNum();
        }
    }

    handlePickPosition(position, isDisabled) {
        if (isDisabled) return;
        this.setState({
            position
        });
        if (this.state.nums.length === 2) {
            this.sendPickedNum();
        }
    }

    handleCheckbox(e) {
        this.setState({
            checkboxChecked: e.target.checked ? 1 : 0
        });
    }

    toggleSelectPopup(status) {
        this.setState({
            showSelectPopup: status
        });
    }

    changeCurrentValue(val) {
        if (val === 0) return;
        const { history, setCurrentValue } = this.props;
        setCurrentValue(val);
        history.push('/home');
    }

    toggleLoading(loading) {
        this.setState({
            loading
        });
    }

    async changeTable() {
        const { currentOption } = this.props;
        const newTable = await utils.getRandomGame(
            currentOption.token.address,
            currentOption.value
        );
        const tableNum = newTable.gameNum;
        if (this.state.tableNum === tableNum) {
            this.setState({
                tableNum: 0,
                pickedNum: [],
                tableData: null,
                position: ''
            });
            this.getTableData(0);
        } else {
            this.setState({ tableNum, pickedNum: [], position: '' });
            this.getTableData(tableNum);
        }
    }

    async sendPickedNum() {
        const { currentOption, history, allPostions } = this.props;
        const dayIndex = await utils.getCurrentDayIndex();
        const accounts = await utils.web3().eth.getAccounts();
        const account = accounts[0];
        const state = this.state;
        if (state.position === '') {
            return;
        }
        const params = {
            tokenAddress: currentOption.token.address,
            dayIndex: dayIndex,
            betValue: currentOption.value,
            gameNum: state.tableNum,
            conflictOption: state.checkboxChecked,
            betNum1: state.nums[0],
            betNum2: state.nums[1],
            position: allPostions.indexOf(state.position)
        };
        this.setState({
            nums: []
        });
        const that = this;
        this.toggleLoading(true);
        utils
            .contractInstance()
            .methods.fillIn(
                params.tokenAddress,
                params.dayIndex,
                params.betValue,
                params.gameNum,
                params.conflictOption,
                params.betNum1,
                params.betNum2,
                params.position
            )
            .send({ from: account, gas: 1000000 })
            .then(function (res) {
                if (res.status) {
                    message.success({
                        content: '投注成功',
                        style: {
                            marginTop: '40vh'
                        }
                    });
                    history.push('/home');
                }
                that.toggleLoading(false);
            })
            .catch(function () {
                message.error('投注失败');
                history.push('/home');
                that.toggleLoading(false);
            });
    }

    render() {
        const { allNumbers, allPostions, currentOption, tokenValueOptions } =
            this.props;
        const { tableData, position, loading } = this.state;
        return (
            <div className={style.pickNumber}>
                {loading || !tableData ? (
                    <div className='spin-bg'>
                        <Spin />
                    </div>
                ) : null}
                <div
                    onClick={() => {
                        this.props.history.push('/home');
                    }}
                    className={style.bg}></div>
                <div className={style.container}>
                    <div className={style.title}>
                        <FormattedMessage id='participateIn' />
                        <span onClick={this.changeTable}>
                            <FormattedMessage id='change' />
                        </span>
                    </div>
                    <p className={style.subtitle}>
                        <FormattedMessage id='infuseAura' />{' '}
                        <span>
                            {utils.formatValueOption(
                                utils.removeDecimal(
                                    currentOption.value,
                                    currentOption.token.decimal
                                ),
                                currentOption.token.unitName,
                                currentOption.token.unitDecimal
                            )}{' '}
                            {currentOption.token.name}
                        </span>
                    </p>
                    <p className={style.hint}>
                        * <FormattedMessage id='neededForReiki' />
                        <span
                            onClick={() => {
                                this.toggleSelectPopup(true);
                            }}>
                            <FormattedMessage id='switchPool' />
                        </span>
                    </p>
                    <p className={style.subtitle}>
                        <FormattedMessage id='selectWinningYoucher' />
                    </p>
                    <p className={style.hint}>
                        * <FormattedMessage id='si' />
                    </p>
                    <div className={style.position}>
                        {allPostions.map((pos, ix) => {
                            return (
                                <span
                                    key={ix}
                                    className={`${style[pos]} 
                                    ${position === pos ? style.picked : ''}
                                    ${
                                        tableData &&
                                        tableData.bettor[ix] !==
                                            constants.blankAddr
                                            ? style.disabled
                                            : ''
                                    }`}
                                    onClick={() => {
                                        this.handlePickPosition(
                                            pos,
                                            tableData &&
                                                tableData.bettor[ix] !==
                                                    constants.blankAddr
                                        );
                                    }}></span>
                            );
                        })}
                    </div>
                    <div className={style.allNumbers}>
                        {allNumbers.map((number) => {
                            const nums = this.state.nums;
                            const pickedNum = this.state.pickedNum;
                            return (
                                <span
                                    onClick={() => {
                                        this.handleClickNumber(number);
                                    }}
                                    key={number}
                                    className={`${
                                        nums.indexOf(number) > -1
                                            ? style.picked
                                            : ''
                                    } ${
                                        pickedNum.indexOf(number) > -1
                                            ? style.disabled
                                            : ''
                                    }`}>
                                    {number}
                                </span>
                            );
                        })}
                    </div>
                    <Checkbox
                        className='pick_checkbox'
                        onChange={this.handleCheckbox}
                        defaultChecked='true'>
                        <FormattedMessage id='itk' />
                    </Checkbox>
                </div>
                {this.state.showSelectPopup && (
                    <SelectPopup
                        close={() => {
                            this.toggleSelectPopup(false);
                        }}
                        buttonText='去切换'
                        options={tokenValueOptions}
                        tokenInfo={currentOption.token}
                        confirm={this.changeCurrentValue}></SelectPopup>
                )}
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        allNumbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        allPostions: ['jin', 'mu', 'shui', 'huo', 'tu'],
        currentOption: state.currentOption,
        tokenValueOptions: state.tokenValueOptions
    };
};

const mapDispatch = (dispatch) => {
    return {
        setCurrentValue(val) {
            dispatch(actionCreators.setCurrentValue(val));
        }
    };
};

export default connect(mapState, mapDispatch)(PickNumber);
