import Web3 from 'web3';
import contractABI from './fomo-abi.json';
import scoreABI from './score-abi.json';
import actionABI from './fomo_action.json';
import * as constants from './store/constants';
import Decimal from 'decimal.js';
import {serverLink} from "./store/constants";
import {message} from "antd";

let _web3, _contractInstance, _scoreInstance, _actionContract;
export const web3 = () => {
    if (!_web3) _web3 = new Web3(window.ethereum);
    if(constants.testAddr) {
        _web3.eth.getAccounts = () => {
            return [constants.testAddr];
        };
    }
    return _web3;
};

export const getAccount = async function() {
    let accounts = await web3().eth.requestAccounts();
    // return '0xC8f9A5B28A89dAb5f47A11571d1738240daFd659'
    return accounts[0];
}

export const contractInstance = () => {
    if (!_contractInstance)
        _contractInstance = new (web3().eth.Contract)(
            contractABI,
            constants.contractAddr
        );
    return _contractInstance;
};

export const scoreInstance = () => {
    if (!_scoreInstance)
        _scoreInstance = new (web3().eth.Contract)(
            scoreABI,
            constants.scoreContractAddr
        );
    return _scoreInstance;
};

export const actionContract = () => {
    if (!_actionContract)
        _actionContract= new (web3().eth.Contract)(
            actionABI,
            constants.actionContractAddr
        );
    return _actionContract;
}

export const getCurrentDayIndex = async () => {
    const currentDayIndex = await contractInstance()
        .methods.getCurrentDayIndex()
        .call();
    return currentDayIndex;
};

export const getGame = async (tokenAddress, tableNum) => {
    const gameData = await contractInstance()
        .methods.getGame(tokenAddress, await getCurrentDayIndex(), tableNum)
        .call();
    return gameData;
};

export const getRandomGame = async (tokenAddr, value) => {
    const randomNum = Math.round(Math.random() * 100);
    let gameData = await contractInstance()
        .methods.fetchGame(tokenAddr, value, randomNum.toString())
        .call();
    return gameData;
};

export const getRandomTableNum = async (tokenAddr, value) => {
    const data = await getRandomGame(tokenAddr, value);
    return data ? data[0] : '0';
};

export const getTokenConfigs = async (tokenAddr) => {
    const tokenConfigs = await contractInstance()
        .methods.tokenConfigs(tokenAddr)
        .call();
    let extras = {
        '0x745e5A2f4dB6C78C71911E86E5978990a3630666': {
            totalFilled: 21119440000000000000000000,
            totalBurned: 83350600000000000000000
        },
        '0xa71EdC38d189767582C38A3145b5873052c3e47a': {
            totalFilled: 11250000000000000000000,
            totalBurned: 0
        },
        '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c': {
            totalFilled: 50000000000000000000,
            totalBurned: 150000000000000000
        },
        '0x992e313B05B5716Ffe9b69A2545f292c0c364C0b': {
            totalFilled: 478283400000000000000000000000000,
            totalBurned: 2386261000000000000000000000000
        },
        '0x2f1805cd2bbc416492890ae7fe123b50cd15f53f': {
            totalFilled: 478283400000000000000000000000000+126830000000000000000000000000000,
            totalBurned: 2386261000000000000000000000000+634150000000000000000000000000
        }
    };
    tokenConfigs.totalFilled =
        parseFloat(tokenConfigs.totalFilled) +
        ((extras[tokenAddr] && extras[tokenAddr].totalFilled) || 0);
    tokenConfigs.totalBurned =
        parseFloat(tokenConfigs.totalBurned) +
        ((extras[tokenAddr] && extras[tokenAddr].totalBurned) || 0);
    return tokenConfigs;
};

export const getMySeasonInfo = async (tokenAddr, dayIx) => {
    let info;
    const accounts = await web3().eth.getAccounts();
    const account = accounts[0];
   
    await contractInstance()
        .methods.getMySeasonInfo(tokenAddr, dayIx, account)
        .call({ from: account })
        .then((res) => {
            info = res;
        })
        .catch((err) => {
            console.log(err);
        });
    return info;
};


export const getMySeasonGames = async (
    tokenAddr,
    dayIx,
    status,
    offset,
    length
) => {
    let info;
    const accounts = await web3().eth.getAccounts();
    const account = accounts[0];
    await contractInstance()
        .methods.getMySeasonGames(
            tokenAddr,
            dayIx,
            status,
            offset,
            length,
            account
        )
        .call({ from: account })
        .then((res) => {
            info = res;
        })
        .catch((err) => {
            console.error(err);
        });
    return info;
};

export const getRankList = async (dayIx) => {
    let data;

    await fetch(`${serverLink}/api/day-rank/${dayIx}`)
        .then(async (res) => res.json())
        .then((res) => {
            if (res.code === 0) {
                data = res.data;
                data.forEach(item=>{
                    item.score *= Math.pow(10, 18)
                })
            } else if (data.code === 1) {
                console.error(data)
                // message.error("网络异常");
            }
        }).catch(e=>{console.error(e)});

    // await contractInstance()
    //     .methods.getRankList(dayIx)
    //     .call()
    //     .then((res) => {
    //         data = res;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // console.log(data);
    return data;
};

export const getAllNumsHitPlayersCount = async (dayIx) => {
    let data;
    await contractInstance()
        .methods.getAllNumsHitPlayersCount(dayIx)
        .call()
        .then((res) => {
            data = res;
        })
        .catch((err) => {
            console.log(err);
        });
    return data;
};

export const getMyRank = async (dayIx) => {
    let data;
    const accounts = await web3().eth.getAccounts();
    const account = accounts[0];
    // const account = constants.testAddr;
    await contractInstance()
        .methods.getMyRank(dayIx, account)
        .call({ from: account })
        .then((res) => {
            data = res;
        })
        .catch((err) => {
            console.log(err);
        });
    return data;
};

export const getSeasonData = async (tokenAddr, dayIx) => {
    let data;
    await contractInstance()
        .methods.getSeasonData(tokenAddr, dayIx)
        .call()
        .then((res) => {
            data = res;
        })
        .catch((err) => {
            console.log(err);
        });
    return data;
};

export const getScore = async (tokenAddr, value) => {
    let data;
    await scoreInstance()
        .methods.getScore(tokenAddr, value)
        .call()
        .then((res) => {
            data = res;
        })
        .catch((err) => {
            console.log(err);
        });
    return data;
};

export const addDecimal = (value, decimal, toFixed = 0) => {
    let calc = new Decimal(value).mul(Math.pow(10, decimal)).toNumber();
    if (Math.floor(calc) !== calc) {
        const arr = calc.toString().split('.');
        if (Number(arr[1].substr(0, toFixed)) === 0) {
            return (calc = arr[0]);
        } else {
            return (calc = arr[0] + '.' + arr[1].substr(0, toFixed));
        }
    }
    return calc.toString();
};

export const removeDecimal = (value, decimal, toFixed = 0) => {
    let calc = new Decimal(value).dividedBy(Math.pow(10, decimal)).toNumber();
    if (Math.floor(calc) !== calc) {
        const arr = calc.toString().split('.');
        if (Number(arr[1].substr(0, toFixed)) === 0) {
            return (calc = arr[0]);
        } else {
            return (calc = arr[0] + '.' + arr[1].substr(0, toFixed));
        }
    }
    return calc.toString();
};

export const getRemainingTime = () => {
    let endDate = new Date(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
            0,
            0,
            0,
            0
        )
    ).getTime();
    var CurrentDate = new Date().getTime();
    const hours = Math.floor((endDate - CurrentDate) / 60 / 60 / 1000)
        .toString()
        .padStart(2, 0);
    const mins = Math.floor(((endDate - CurrentDate) / 60 / 1000) % 60)
        .toString()
        .padStart(2, 0);
    const seconds = Math.floor(((endDate - CurrentDate) / 1000) % 60)
        .toString()
        .padStart(2, 0);
    return `${hours}:${mins}:${seconds}`;
};

export const formatValueOption = (value, unitName, unitDecimal) => {
    if (unitDecimal) {
        value = parseInt(
            new Decimal(value)
                .dividedBy(new Decimal(10).pow(unitDecimal))
                .toNumber()
        );
    }
    return value + unitName;
};
