import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../store/actionCreators';
import * as constants from '../store/constants';
import * as utils from '../utils';
import { Link } from 'react-router-dom';
import style from '../style/home.module.scss';
import Menu from '../components/Menu';
import InfoBox from '../components/InfoBox';
import Table from '../components/Table';
import Rules from '../components/Rules';
import Rank from '../components/Rank';
import Countdown from '../components/Countdown';
import BrocadeBag from '../components/BrocadeBag';
import Trophies from '../components/Trophies';
import Spin from '../components/Spin';
import { Select, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import HammerImg from '../images/hammer_icon.png';

let dayIndex;

const getRandomGame = async (address, value) => {
    const recommendTable = await utils.getRandomGame(address, value);
    return recommendTable;
};

//本期灵气池数量
const getSeasonData = async (currentAddr) => {
    const options = constants.tokenOptions;
    const totalRewardAmounts = {};
    let totalPlayingGameNum = 0;
    for (let i = 0; i < options.length; i++) {
        const seasonData = await utils.getSeasonData(
            options[i].address,
            dayIndex
        );
        if (seasonData) {
            if (options[i].address === currentAddr) {
                totalPlayingGameNum = seasonData.totalPlayingGameNum;
            }
            totalRewardAmounts[options[i].name] = utils.formatValueOption(
                utils.removeDecimal(
                    seasonData.totalRewardAmount,
                    options[i].decimal
                ),
                options[i].unitName,
                options[i].unitDecimal
            );
        }
    }
    return {
        totalPlayingGameNum,
        totalRewardAmounts
    };
};

const getMySeasonInfo = async (currentAddr, currentDecimal) => {
    const data = await utils.getMySeasonInfo(currentAddr, dayIndex);
    const myGold = utils.removeDecimal(data.score, currentDecimal, 1);
    const myRank = await utils.getMyRank(dayIndex);
    const hitNums = data.hitNums.join(' ');
    return { myGold, myRank: Number(myRank), hitNums };
};

const getMyGold = async (account, dayIx) => {
    const res = await fetch(
        `${constants.serverLink}/api/player-day-info/${account}/${dayIx}`
    ).then(async (res) => res.json());
    if (res.code === 0) {
        return utils.removeDecimal(res.data.score, 0, 1);
    } else {
        return 0;
    }
};

const getTokenConfigs = async (currentOption) => {
    const token = currentOption.token;
    const data = await utils.getTokenConfigs(token.address);
    return {
        enabled: data.enabled,
        burnRatio: data.burnRatio,
        totalFilled: utils.formatValueOption(
            utils.removeDecimal(data.totalFilled, token.decimal),
            token.unitName,
            token.unitDecimal
        ),
        totalDestroyed: utils.formatValueOption(
            utils.removeDecimal(data.totalBurned, token.decimal),
            token.unitName,
            token.unitDecimal
        )
    };
};

const getOtherData = async (currentOption, currentValue, lang) => {
    try {
        const currentAddr = currentOption.token.address;
        const { totalPlayingGameNum, totalRewardAmounts } = await getSeasonData(
            currentAddr
        );
        const rankList = await utils.getRankList(dayIndex);
        const goldRate = await utils.getScore(currentAddr, currentValue);
        const allNumsHitPlayersCount = await utils.getAllNumsHitPlayersCount(
            dayIndex
        );
        const mySeasonInfo = await getMySeasonInfo(
            currentAddr,
            currentOption.token.decimal
        );
        const tokenConfigs = await getTokenConfigs(currentOption);
        return {
            ...{
                totalRewardAmounts,
                totalPlayingGameNum,
                rankList,
                goldRate: utils.removeDecimal(
                    goldRate,
                    constants.scoreDecimal,
                    2
                ),
                allNumsHitPlayersCount
            },
            ...mySeasonInfo,
            ...tokenConfigs
        };
    } catch {
        if (lang === 'zh') {
            message.error('网络连接超时，请再次重试');
        } else {
            message.error('Network timeout, please try again');
        }
        return null;
    }
};

const Home = (props) => {
    const { tokenOptions, tokenValueOptions, currentOption, lang } =
        useSelector((state) => state);
    const currentAddr = currentOption.token.address;
    const dispatch = useDispatch();
    const [recommendTableData, setRecommendTableData] = useState([]);
    const [showRules, setShowRules] = useState(false);
    const [currentInfoBoxTab, setCurrentInfoBoxTab] = useState(0);
    const [ableToSwitch, setAbleToSwitch] = useState(false);
    const [account, setAccount] = useState('');
    const [ready, setReady] = useState(false);
    const [myGold, setMyGold] = useState(0);
    const [otherData, setOtherData] = useState({
        totalRewardAmounts: {},
        totalPlayingGameNum: 0,
        rankList: [],
        goldRate: 0,
        allNumsHitPlayersCount: 0,
        myRank: 0,
        hitNums: '',
        enabled: true,
        burnRatio: 0,
        totalFilled: 0,
        totalDestroyed: 0
    });
    const [currentValue, setCurrentValue] = useState(currentOption.value);

    const init = async () => {
        dayIndex = await utils.getCurrentDayIndex();
        const accounts = await utils.web3().eth.getAccounts();
        setAccount(accounts[0]);
        setReady(false);
        const myGold = await getMyGold(accounts[0], dayIndex);
        setMyGold(myGold);
        try {
            if (currentValue === '0') {
                try {
                    await getTokenValueOptions();
                } catch (e) {
                    console.log(e);
                    message.error('获取代币对应值列表失败');
                }
            }
            if (currentValue !== '0') {
                try {
                    const tableData = await getRandomGame(
                        currentAddr,
                        currentValue
                    );
                    if (tableData.gameNum === '0') {
                        setAbleToSwitch(false);
                    } else {
                        setAbleToSwitch(true);
                    }
                    setRecommendTableData(tableData);
                    setReady(true);
                } catch (e) {
                    console.log(e);
                    message.error('获取推荐桌子失败');
                }
                try {
                    const otherData = await getOtherData(
                        currentOption,
                        currentValue,
                        lang
                    );
                    if (otherData) setOtherData(otherData);
                } catch (e) {
                    console.log(e);
                    message.error('获取其他数据失败');
                }
            }
        } catch (e) {
            console.error(e);
            if (lang === 'zh') {
                message.error(
                    'HECO链切换失败，请点击右上角 … 切换账户--》点击HT（手动连接一次即可）'
                );
            } else {
                message.error(
                    'HECO chain switch failed, please click the upper right corner ... switch account --> click HT (only needs to manual connection once)'
                );
            }
            setReady(true);
        }
    };

    const goPickNumber = (tableNum) => {
        props.history.push('/pick/' + tableNum);
    };

    const quickStart = async () => {
        const tableNum = await utils.getRandomTableNum(
            currentAddr,
            currentValue
        );
        goPickNumber(tableNum);
    };

    const getTokenValueOptions = async () => {
        let tokenValueOptions = await utils
            .contractInstance()
            .methods.getTokenValueOptions(currentAddr)
            .call();
        if (tokenValueOptions.length) {
            dispatch(actionCreators.setTokenValueOptions(tokenValueOptions));
            dispatch(actionCreators.setCurrentValue(tokenValueOptions[0]));
            setCurrentValue(tokenValueOptions[0]);
        }
    };

    const changeCurrentValue = async (val) => {
        dispatch(actionCreators.setCurrentValue(val));
        setCurrentValue(val);
    };

    const changeCurrentToken = async (val) => {
        let tokenData = {};
        for (let k in tokenOptions) {
            if (tokenOptions[k].name === val) {
                tokenData = tokenOptions[k];
                break;
            }
        }
        dispatch(actionCreators.setCurrentTokenData(tokenData));
        dispatch(actionCreators.setCurrentValue(0));
        setCurrentValue('0');
    };

    const handleSwitchTable = async () => {
        if (!ableToSwitch || currentValue === '0') {
            if (lang === 'zh') {
                message.info('当前没有可用的桌子，请新建');
            } else {
                message.info(
                    'There is no table currently available, please create a new one'
                );
            }
            return;
        }
        setAbleToSwitch(false);
        const newRecommendTable = await getRandomGame(
            currentAddr,
            currentValue
        );
        setRecommendTableData(newRecommendTable);
        setAbleToSwitch(true);
        setReady(true);
        const curGameNum = Number(recommendTableData.gameNum);
        const newGameNum = Number(newRecommendTable.gameNum);
        if (newGameNum === curGameNum) {
            if (lang === 'zh') {
                message.info('换到了同样的五行阵？请重试或新建');
            } else {
                message.info(
                    'Switched to the same five element array? Please change again or create a new one.'
                );
            }
        }
    };

    const setCurrentLang = () => {
        const lang = localStorage.getItem('lang');
        const setLang = lang === 'zh' ? 'en' : 'zh';
        dispatch(actionCreators.setCurrentLang(setLang));
    };

    const toggleLoading = (status) => {
        setReady(!status);
    };

    useEffect(() => {
        window.ethereum
            .enable()
            .then(async () => {
                init();
            })
            .catch(() => {
                alert('请刷新页面并授权钱包');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    const { Option } = Select;
    return (
        <div className={style.home}>
            {!ready ? (
                <div className='spin-bg'>
                    <Spin />
                </div>
            ) : null}
            <div className={style.header}>
                <Select
                    style={{ float: 'left' }}
                    key={currentOption.token.name}
                    defaultValue={currentOption.token.name}
                    onChange={changeCurrentToken}
                    showArrow={false}>
                    {tokenOptions.map((token, ix) => {
                        return (
                            <Option key={ix} value={token.name}>
                                {token.name}
                            </Option>
                        );
                    })}
                </Select>
                <Select
                    style={{ float: 'left' }}
                    key={`${utils.removeDecimal(
                        currentOption.value,
                        currentOption.token.decimal
                    )}${' '}${currentOption.token.name}`}
                    defaultValue={`${utils.formatValueOption(
                        utils.removeDecimal(
                            currentOption.value,
                            currentOption.token.decimal
                        ),
                        currentOption.token.unitName,
                        currentOption.token.unitDecimal
                    )}${' '}${currentOption.token.name}`}
                    onChange={changeCurrentValue}
                    dropdownClassName={style.dropdown}
                    showArrow={false}>
                    {tokenValueOptions.map((val, ix) => {
                        return (
                            <Option key={ix} value={val}>
                                {utils.formatValueOption(
                                    utils.removeDecimal(
                                        val,
                                        currentOption.token.decimal
                                    ),
                                    currentOption.token.unitName,
                                    currentOption.token.unitDecimal
                                )}{' '}
                                {currentOption.token.name}
                            </Option>
                        );
                    })}
                </Select>
                <div onClick={setCurrentLang} className={style.lang}></div>
                <Menu history={props.history} />
            </div>
            {otherData.burnRatio !== '0' && (
                <div className={style.infoBoxTabs}>
                    <span
                        className={currentInfoBoxTab === 0 ? style.active : ''}
                        onClick={() => {
                            setCurrentInfoBoxTab(0);
                        }}>
                        {/* 累计注入灵气总量 */}
                        <FormattedMessage id='ct' />
                    </span>
                    <span
                        className={currentInfoBoxTab === 1 ? style.active : ''}
                        onClick={() => {
                            setCurrentInfoBoxTab(1);
                        }}>
                        {/* 累计灵气销毁池总量 */}
                        <FormattedMessage id='ctp' />
                    </span>
                </div>
            )}
            <InfoBox
                heading={
                    currentInfoBoxTab === 0 ? (
                        <>
                            <FormattedMessage id='ct' />(
                            {currentOption.token.name})
                        </>
                    ) : (
                        <>
                            <FormattedMessage id='ctp' />(
                            {currentOption.token.name})
                        </>
                    )
                }
                number={
                    currentInfoBoxTab === 0
                        ? otherData.totalFilled
                        : otherData.totalDestroyed
                }
            />
            <div className={style.main}>
                <div className={style.buttons}>
                    <button onClick={quickStart}>
                        <FormattedMessage id='participateQuickly' />
                    </button>
                    <p className={style.rate}>
                        {utils.formatValueOption(
                            utils.removeDecimal(
                                currentOption.value,
                                currentOption.token.decimal
                            ),
                            currentOption.token.unitName,
                            currentOption.token.unitDecimal
                        )}
                        {currentOption.token.name} {'= '}
                        <span>
                            {otherData.goldRate}
                            <FormattedMessage id='elixir' />
                        </span>
                    </p>
                    <button
                        onClick={() => {
                            props.history.push('/tables/finished');
                        }}
                        className={style.right}>
                        <FormattedMessage id='betted' />
                    </button>
                </div>
                {/* <img
                    src={HammerImg}
                    className={style.hammer}
                    alt='game entry'
                    onClick={() => {
                        // message.info('升级中，稍后回来');
                        window.location.href = 'https://wyf.fivexw.com/';
                    }}
                /> */}
                {recommendTableData.length && (
                    <Link to={`/pick/${recommendTableData.gameNum}`}>
                        <Table
                            data={recommendTableData}
                            fromHome={true}
                            account={account}
                            currentToken={currentOption.token}></Table>
                    </Link>
                )}
            </div>
            <div className={style.changeTable}>
                <button
                    className={style.newTable}
                    onClick={() => {
                        goPickNumber(0);
                    }}>
                    <FormattedMessage id='createNewTable' />
                </button>
                <p>
                    <FormattedMessage id='tableAmount1' />
                    <span>{otherData.totalPlayingGameNum}</span>
                    <FormattedMessage id='tableAmount2' />
                </p>
                <button onClick={handleSwitchTable}>
                    <FormattedMessage id='changeTable' />
                </button>
            </div>
            <div className={style.rules}>
                <p
                    onClick={() => {
                        setShowRules(true);
                    }}
                    className={style.title}>
                    <FormattedMessage id='gameRules' />
                </p>
            </div>
            {Object.keys(otherData.totalRewardAmounts).length ? (
                <div className={style.mainTitle}>
                    <p>
                        <FormattedMessage id='reikiRewardAmount' />
                    </p>
                    <div className={style.countdown}>
                        <Countdown></Countdown>
                    </div>
                </div>
            ) : null}
            <Trophies />
            {otherData.rankList.length ? (
                <Rank
                    myGold={myGold}
                    myRank={otherData.myRank}
                    rankList={otherData.rankList}></Rank>
            ) : null}
            {otherData.goldRate ? (
                <div className={style.mainTitle}>
                    <p className={style.onTheListStatus}>
                        <FormattedMessage id='decryptedNumber' />:{' '}
                        <span className={style.hitPlayers}>
                            {otherData.allNumsHitPlayersCount}
                        </span>
                        <span
                            className={`${style.img} ${
                                otherData.hitNums.length < 19
                                    ? ''
                                    : style.active
                            }`}>
                            <span>
                                {otherData.hitNums.length < 19 ? (
                                    <FormattedMessage id='notOnTheList' />
                                ) : (
                                    <FormattedMessage id='onTheList' />
                                )}
                            </span>
                        </span>
                    </p>
                    {account !== '' && otherData.hitNums.length >= 19 ? (
                        <BrocadeBag
                            myGold={myGold}
                            account={account}
                            dayIndex={dayIndex}
                            toggleLoading={toggleLoading}
                        />
                    ) : null}
                </div>
            ) : null}
            {otherData.goldRate ? (
                <InfoBox
                    style={{ paddingTop: '10px' }}
                    heading={
                        <>
                            <FormattedMessage id='myNumbers' />
                        </>
                    }
                    number={
                        otherData.hitNums === '' ? (
                            <FormattedMessage id='none' />
                        ) : (
                            otherData.hitNums
                        )
                    }
                />
            ) : null}
            {showRules && (
                <Rules
                    close={() => {
                        setShowRules(false);
                    }}></Rules>
            )}
        </div>
    );
};

export default Home;
