const en = {
    wtf: 'Welcome to FOMO Five Elements Space!',
    jg: 'Please don’t have too many illusions, this is just a game...',
    jg2: ' ',
    ok: 'OK',
    ct: 'total aura injected',
    ctp: 'total aura destruction pool',
    noOngoing: 'There is currently no ongoing five element array',
    reiki: 'Number of Reiki Reward Pools in this Period',
    Countdown: 'Countdown to draw',
    elixirRanking: "Today's Elixir ranking",
    myElixir: 'My Elixir',
    elixir: 'Elixir',
    participateQuickly: 'Quick Start',
    betted: 'Betted',
    completed: 'Completed',
    initiateImmediately: 'Initiate',
    gameRules: 'Game rules',
    revelation: 'Revelation',
    revelationTxt: `Everything has a spirit, all start from the five elements! When the first aura is injected into the five elements of FOMO, it will run on the blockchain forever. The theory of metaphysics is profound and profound, the five elements are mutually reinforcing, the digital energy contains magnetic feng shui, the time stamp, block height, block size and other factors determine the unique hash value, a fusion of metaphysics and cryptography, is it a prank or a divine stroke, let's wait and see!`,
    participateIn: 'Participate in the Five Elements Bagua Formation',
    change: 'change',
    infuseAura: 'infuse aura',
    neededForReiki: 'Refill other amount needed for Reiki',
    switchPool: 'Switch pool',
    selectWinningYoucher: 'Select winning voucher',
    si: 'Select the direction and two numbers, it will take effect immediately after clicking',
    itk: 'If it conflicts with the number selected by other users, the contract will create a new five-element gossip matrix for you, keeping the number you selected',
    ongoing: 'Ongoing',
    numbersHaveBeenHit: 'Numbers have been hit',
    wayOfParticipation: 'How to participate',
    wayOfParticipationTxt1:
        'Open FOMO five elements array need gold, wood, water, fire, earth five elements aggregation, each player can be in a different aura field and aura value to switch the game, players',
    wayOfParticipationTxt2:
        'select a five element direction (one of gold, wood, water, fire and earth) and then two numerical energies (0-9)',
    wayOfParticipationTxt3:
        ', complete the chain Reiki injection to participate in the game. Each game of five elements orientation and numerical energy can not be repeated, first come first served!',
    decrypt: 'Decryption',
    decryptTxt1:
        'Five elements gather to open the chain decryption, the last digit of the unique hash value generated on the chain (if the last digit is a letter shifted forward in line), that is, the decryption number, choose this number players consume their injected aura to get the corresponding amount of Elixir (value 1U = 1 Elixir).',
    pillFurnaceRewardRules: 'Dangling furnace reward rules',
    pillFurnaceRewardRulesTxt1: 'Reward once every 24 hours (one period),',
    pillFurnaceRewardRulesTxt2: '100% reward',
    pillFurnaceRewardRulesTxt3: 'the current Elixir player rankings',
    pillFurnaceRewardRulesTxt4:
        'top3 takes 60% of the pill furnace aura in proportion',
    pillFurnaceRewardRulesTxt5:
        'top4-10 will take 30% of the pill furnace aura in proportion',
    pillFurnaceRewardRulesTxt6:
        'players whose numbers are decrypted in the current period(0-9)',
    pillFurnaceRewardRulesTxt7:
        'equally distribute 10% of the pill furnace aura',
    mine: 'Mine',
    homepage: 'Home',
    market: 'Trading Market',
    onlyShow: 'Only show current Reiki related data',
    currency: 'Reiki type (currency)',
    bonus: "Yesterday's bonus",
    todayData: "Today's completed",
    draw: 'Draw',
    over: 'Over',
    cancelled: 'Cancelled',
    myNumbers: 'My numbers',
    reportLink: 'Audit Report',
    createNewTable: 'Create',
    tableAmount1: 'There are currently',
    tableAmount2: 'five element array waiting to be aggregated',
    changeTable: 'Switch',
    reikiRewardAmount: 'Number of Reiki reward pools for this period',
    AURAHolderRewards: 'AURA holder rewards are continuously issued',
    decryptedNumber:
        'Number of players for which all numbers are decrypted in this period',
    splittingPrize: 'Average share of prize pool',
    proportionalDivision: 'Proportional division of the prize pool',
    rulesHintTxt1:
        '*1U=1 Elixir, other coins get the number of Elixir corresponding to the proportional relationship with usdt.',
    rulesHintTxt2:
        '*If there are less than 20 players holding the Elixir, the Elixir of the furnace will not be divided, and the Elixir of the furnace and the Elixir held by the user will be accumulated to the next period.',
    rulesHintTxt3:
        '*The number of players with all numbers decrypted in the current period is 0. 10% of the Elixir of the pottery will be accumulated to the next period, and if there are less than 20 players holding immortal pills in the current period, 90% of the Elixir of the pottery will not be divided and will be accumulated to the next period.',
    bonusList: 'Full list',
    bonusPoolPrizes: 'Full list of bonus pool prizes',
    bonusPoolPrizesHint:
        '*All types of prizes will be awarded to the toppers in the same proportion.',
    notOnTheList: 'Not listed',
    onTheList: 'Listed',
    none: 'None',
    switch: 'Switch',
    myUnion: 'My union',
    loadMore: 'Load more',
    viewMore: 'View more',
    notViewMore: 'Close',
    currentLevel: 'Current level',
    firstPlace: 'First place!',
    rank: 'Rank',
    walletAddress: 'Address',
    numberOfElixir: 'Elixir',
    myName:'swordsman',
    myLevel:'my level',
    currentDividendRatio: 'Dividend Ratio',
    myLingDan: 'My panaceas',
    lingDanDetails: 'Panaceas details',
    totalLingdan: 'Total Panaceas',
    brocadeTitle: 'Open the bag',
    brocadeItem1: 'Decrypting brocade hash',
    brocadeItem2: 'Number of Elixir Gifts',
    brocadeItem3: 'Number of AURA gifts',
    reissuing: `Re-issuing yesterday's brocade`,
    Beacon: 'Beacon',
    totalElixirKing: 'Total king',
    singleElixirKing: 'Current king',
    activity: 'Personal activity',
    activity_day: `Today's activity`,
    activity_total: 'Total activity',
    shengwuDetails: 'my shengwu',
    
    tpRevenue:'今日推广收益',
    disRevenue:'已分发收益',
    waitRevenue:'待分发收益',
    proTask:'推广任务',
    dailyTask:'日常任务',
    achTask:'成就任务',
    allReceive:'一键领取',
    progress:'进行中',
    receive:'领取',
    received:'已领取',
    myInvite:'我的邀请',
    lookDetail:'查看明细',
    successInvite:'成功邀请(人)',
    awarded:'已获得奖励',
    inviteFriend:'邀请好友',
    successCopy:'成功复制链接',
    inviteAddr:'邀请链接地址',
    congratulations:'恭喜您',
    successAward:'成功领取奖励',
    address:'地址',
    lockType:'锁仓类型',
    count:'数量',
};
export default en;
