import { connect } from 'react-redux';
import { Fragment } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import * as actionCreators from '../store/actionCreators';

import '../style/reset.css';
import '../style/antd.scss';
import Entry from './Entry';
import Home from './Home';
import Tables from './Tables';
import Profile from './Profile';
import PickNumber from './PickNumber';
import LingDanDetails from './LingDanDetails';
import Market from './Market';
import ShengwuDetails from './ShengwuDetails';
import JianghuOrder from './JianghuOrder';
import InvitationDetails from './InvitationDetails';
import { IntlProvider } from 'react-intl';
import zh from '../lang/zh';
import en from '../lang/en';

const mapState = (state) => {
    return {
        lang: state.lang
    };
};

const mapDispatch = (dispatch) => {
    return {
        setCurrentLang() {
            const lang = localStorage.getItem('lang');
            const setLang = lang ? lang : 'zh';
            dispatch(actionCreators.setCurrentLang(setLang));
        }
    };
};

function Index(props) {
    let messages = {};
    messages['en'] = en;
    messages['zh'] = zh;
    props.setCurrentLang();
    return (
        <IntlProvider locale={props.lang} messages={messages[props.lang]}>
            <HashRouter>
                <Fragment>
                    <Route path='/' exact component={Entry}></Route>
                    <Route path='/home' exact component={Home}></Route>
                    <Route path='/jianghuOrder' exact component={JianghuOrder}></Route>
                    <Route path='/invitationDetails' exact component={InvitationDetails}></Route>
                    <Route
                        path='/tables/:status'
                        exact
                        component={Tables}></Route>
                    <Route path='/profile' exact component={Profile}></Route>
                    <Route
                        path='/pick/:tableNum'
                        exact
                        component={PickNumber}></Route>
                    <Route
                        path='/lingDanDetails'
                        exact
                        component={LingDanDetails}></Route>
                    <Route path='/market' exact component={Market}></Route>
                        <Route
                        path='/shengwuDetails'
                        exact
                        component={ShengwuDetails}></Route>
                </Fragment>
            </HashRouter>
        </IntlProvider>
    );
}

export default connect(mapState, mapDispatch)(Index);
