import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/home.module.scss';
import { serverLink } from '../store/constants';
import { FormattedMessage } from 'react-intl';
import { message } from 'antd';
import { web3 } from '../utils';
import { rewardBagAddress } from '../store/constants';

let sendBagIdFlag = false;
let interval;
const getData = async (
    props,
    toggleLoading,
    setInfo,
    setShowBox,
    lang,
    firstTime = false
) => {
    toggleLoading(true);
    let errMsg = lang === 'zh' ? '网络错误' : 'Network errors';
    try {
        const account = props.account;
        const result = await new Promise((resolve, reject) => {
            fetch(
                `${serverLink}/api/reward-bag/${account}/day-index/${props.dayIndex}`
            )
                .then(async (res) => res.json())
                .then(async (data) => {
                    console.log(data);
                    if (data.code === 0) {
                        const _data = data.data;
                        if (!_data) {
                            let noDataMsg =
                                lang === 'zh' ? '无数据' : 'No data';
                            message.error(noDataMsg);
                            clearInterval(interval);
                            toggleLoading(false);
                            reject(null);
                        }
                        setInfo(formatData(_data));
                        if (_data?.Status) {
                            if (!firstTime) setShowBox(true);
                            clearInterval(interval);
                            toggleLoading(false);
                        }
                        resolve(_data);
                    } else {
                        message.error(errMsg);
                        clearInterval(interval);
                        toggleLoading(false);
                        reject(null);
                    }
                });
        });
        return result;
    } catch (e) {
        console.log(e);
        message.error(errMsg);
        toggleLoading(false);
        return null;
    }
};

const sendBagId = async (
    props,
    id,
    toggleLoading,
    setInfo,
    setShowBox,
    lang
) => {
    var stringtoHex = function (str) {
        var val = '';
        for (var i = 0; i < str.length; i++) {
            if (val === '') val = str.charCodeAt(i).toString(16);
            else val += str.charCodeAt(i).toString(16);
        }
        return val;
    };

    let res;
    if (!sendBagIdFlag) {
        res = await new Promise((resolve, reject) => {
            web3().eth.sendTransaction(
                {
                    from: props.account,
                    to: rewardBagAddress,
                    amount: 0,
                    data: stringtoHex(`openBag:${id}`)
                },
                (err, res) => {
                    if (!err) {
                        console.log(res);
                        interval = setInterval(async () => {
                            await getData(
                                props,
                                toggleLoading,
                                setInfo,
                                setShowBox,
                                lang
                            );
                        }, 3000);
                        resolve(true);
                    } else {
                        sendBagIdFlag = false;
                        console.log(err);
                        reject(false);
                    }
                }
            );
        });
    }

    return res;
};

const formatData = (data) => {
    const hash = ('...' + data.OpenBlockHash.substr(-20)).split('');
    const highlightIx = [];
    let numCount = 2;
    for (let i = hash.length - 1; i >= 0; i--) {
        if (hash[i] >= 0 && hash[i] <= 9) {
            highlightIx[numCount--] = i;
        }
        if (numCount < 0) {
            break;
        }
    }
    return {
        highlightIx,
        OpenBlockHash: hash,
        OpenRewardPoint: data.OpenRewardPoint,
        OpenRewardToken: data.OpenRewardToken,
        Status: data.Status
    };
};

const handleShowBox = async (
    props,
    toggleLoading,
    setInfo,
    setShowBox,
    status,
    lang
) => {
    if (status) return;
    if (props.myGold < 188) {
        if (lang === 'zh') {
            message.info('当你拥有188仙丹后，请再尝试打开此锦囊');
        } else {
            message.info('Open this reward after get 188 Elixir');
        }
        return;
    }
    const info = await getData(props, toggleLoading, setInfo, setShowBox, lang);
    if (!info.Status) {
        try {
            await sendBagId(
                props,
                info.ID,
                toggleLoading,
                setInfo,
                setShowBox,
                lang
            );
        } catch {
            let injectedMsg =
                lang === 'zh' ? '授权失败' : 'Authorization failed';
            message.info(injectedMsg);
            toggleLoading(false);
        }
    }
};

const BrocadeBag = (props) => {
    const lang = useSelector((state) => state.lang);
    const { toggleLoading } = props;
    const [showBox, setShowBox] = useState(false);
    const [info, setInfo] = useState({
        highlightIx: [],
        OpenBlockHash: [],
        OpenRewardPoint: 0,
        OpenRewardToken: 0,
        Status: 1
    });
    useEffect(() => {
        const _init = async () => {
            await getData(
                props,
                toggleLoading,
                setInfo,
                setShowBox,
                lang,
                true
            );
            toggleLoading(false);
        };
        _init();
        // eslint-disable-ncext-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={style.brocadeBag}>
            <div
                className={`${style.brocadeBagIcon} ${
                    !info.Status ? style.active : ''
                }`}
                onClick={() => {
                    handleShowBox(
                        props,
                        toggleLoading,
                        setInfo,
                        setShowBox,
                        info.Status,
                        lang
                    );
                }}></div>
            {showBox && (
                <div className={style.bg}>
                    <div className={style.box}>
                        <p className={style.title}>
                            <FormattedMessage id='brocadeTitle' />
                        </p>
                        <p className={style.item}>
                            <span>
                                <FormattedMessage id='brocadeItem1' />
                            </span>
                            <span className={`${style.content} ${style.hash}`}>
                                {info.OpenBlockHash.length > 0
                                    ? info.OpenBlockHash.map((v, i) => {
                                          return (
                                              <span
                                                  key={i}
                                                  className={
                                                      info.highlightIx.indexOf(
                                                          i
                                                      ) > -1
                                                          ? style.hightlight
                                                          : ''
                                                  }>
                                                  {v}
                                              </span>
                                          );
                                      })
                                    : null}
                            </span>
                        </p>
                        <p className={style.item}>
                            <span>
                                <FormattedMessage id='brocadeItem2' />
                            </span>
                            <span className={style.content}>
                                {info.OpenRewardPoint}
                            </span>
                        </p>
                        <p className={style.item}>
                            <span>
                                <FormattedMessage id='brocadeItem3' />
                            </span>
                            <span className={style.content}>
                                {info.OpenRewardToken}
                            </span>
                        </p>
                        <button
                            onClick={() => {
                                setShowBox(false);
                            }}>
                            确认收下
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrocadeBag;
