const zh = {
    wtf: '欢迎来到五行空间！',
    jg: '万物有灵，一指乾坤，五行聚合，点石成金……',
    jg2: '坚持到最后的，都是赢家！',
    ok: '确定',
    ct: '累计注入灵气总量',
    ctp: '累计灵气销毁池总量',
    noOngoing: '当前没有正在进行的五行八卦阵',
    reiki: '本期灵气奖励池数量',
    Countdown: '瓜分倒计时',
    participateQuickly: '快速参与',
    elixirRanking: '今日仙丹排行榜',
    myElixir: '我的仙丹',
    elixir: '仙丹',
    betted: '已投注',
    completed: '已完成',
    initiateImmediately: '立即发起',
    gameRules: '游戏规则',
    revelation: '启示录',
    revelationTxt:
        '万物有灵，皆起于五行！当第一道灵气注入FOMO五行，它将永无休止的在区块链上运行下去，玄学理论博大精深，五行相生相克，数字能量蕴含磁场风水，时间戳、区块高度、区块大小等因素决定了独一无二的哈希值，一场玄学和密码学的融合，是恶作剧，还是神来之笔，让我们拭目以待！',
    participateIn: '参与五行八卦阵',
    change: '换一个',
    infuseAura: '注入灵气',
    neededForReiki: '填充其他数额灵气需要',
    switchPool: '切换场池',
    selectWinningYoucher: '选择中奖凭证',
    si: '选择方位和两个数字，点击后立即生效',
    itk: '若和其他用户选择的数字冲突，合约会为你创建新五行八卦阵，保留您选择的数字',
    ongoing: '正在进行',
    numbersHaveBeenHit: '已中数字',
    wayOfParticipation: '参与方式',
    wayOfParticipationTxt1:
        '开启FOMO五行阵需要金、木、水、火、土 五行聚合，每位玩家可在不同的灵气场和灵气值进行切换游戏，玩家',
    wayOfParticipationTxt2:
        '选定一个五行方位（金木水火土中的一个），再选择两个数字能量（0-9）',
    wayOfParticipationTxt3:
        '，完成链上灵气注入，即可参与游戏。每一局五行方位和数字能量不可重复，先到先选！',
    decrypt: '解密',
    decryptTxt1:
        '五行聚集开启链上解密，链上生成的唯一哈希值最后一位数字（若最后一位为字母往前顺移），即为解密数字，选择此数字玩家消耗其注入的灵气，获得对应数额的仙丹（价值1U=1仙丹），其他玩家平分灵气的96%，3%的灵气进入丹炉，0.2%的灵气销毁（U区回购AURA销毁，其他代币区直接销毁代币），0.3%的灵气奖励给周排行榜Top10，0.5%的灵气燃烧。',
    pillFurnaceRewardRules: '丹炉奖励规则',
    pillFurnaceRewardRulesTxt1: '24小时(一期)奖励一次，',
    pillFurnaceRewardRulesTxt2: '100%奖励',
    pillFurnaceRewardRulesTxt3: '当期持有仙丹玩家排行榜',
    pillFurnaceRewardRulesTxt4: 'top3按比例分走丹炉灵气的60%',
    pillFurnaceRewardRulesTxt5: 'top3-10按比例分走丹炉灵气的30%',
    pillFurnaceRewardRulesTxt6: '当期所有号码都解密的玩家(0-9)',
    pillFurnaceRewardRulesTxt7: '均分丹炉灵气的10%',
    mine: '我的',
    homepage: '首页',
    market: '交易市场',
    onlyShow: '只展示当前灵气相关数据',
    currency: '灵气类型(币种)',
    bonus: '昨日奖金',
    todayData: '今日已完成',
    draw: '开奖中',
    over: '已结束',
    cancelled: '已取消',
    myNumbers: '我的解密',
    reportLink: '审计报告',
    createNewTable: '新建',
    tableAmount1: '当前共有',
    tableAmount2: '五行阵等待聚合',
    changeTable: '换一个',
    reikiRewardAmount: '本期灵气奖励池数量',
    AURAHolderRewards: 'AURA持有人奖励持续发放',
    decryptedNumber: '本期所有数字均解密玩家数',
    splittingPrize: '平均瓜分奖池',
    proportionalDivision: '按比例瓜分奖池',
    rulesHintTxt1: '*1U=1仙丹，其他币种根据与usdt的比例关系对应获得仙丹数。',
    rulesHintTxt2:
        '*若持有仙丹玩家少于20人，丹炉灵气不瓜分，丹炉灵气和用户持有的仙丹累计到下期；',
    rulesHintTxt3:
        '*当期所有数字都解密的玩家数为0，丹炉10%灵气累计到下期，当期仙丹持有玩家少于20人，丹炉90%灵气不瓜分，累计到下期。',
    bonusList: '奖金全览',
    bonusPoolPrizes: '奖励池奖金全览',
    bonusPoolPrizesHint: '*所有类型奖金均按照相同比例奖励上榜者。',
    notOnTheList: '未上榜',
    onTheList: '已上榜',
    none: '无',
    switch: '切换',
    myUnion: '我的公会',
    loadMore: '加载更多',
    viewMore: '查看更多',
    notViewMore: '收起',
    currentLevel: '当前等级',
    firstPlace: '第一名！',
    rank: '排名',
    walletAddress: '钱包地址',
    numberOfElixir: '仙丹数量',
    currentDividendRatio: '当前分红比例',
    myName:'侠客名讳',
    myLevel:'我的等级',
    myShengwu:'我的圣物',
    myLingDan: '我的灵丹',
    lingDanDetails: '灵丹明细',

    totalLingdan: '累计灵丹',
    brocadeTitle: '开启锦囊',
    brocadeItem1: '解密锦囊哈希',
    brocadeItem2: '赠送灵丹数量',
    brocadeItem3: '赠送AURA数量',
    reissuing: '补发昨日锦囊',
    Beacon: '烽火台',
    totalElixirKing: '总仙丹王',
    singleElixirKing: '单期仙丹王',
    activity: '个人活跃度',
    activity_day: '当日活跃度',
    activity_total: '总活跃度',
    shengwuDetails: '我的圣物',
    totalLingdan: '累计灵丹',

    tpRevenue:'今日推广收益',
    disRevenue:'已分发收益',
    waitRevenue:'待分发收益',
    proTask:'推广任务',
    dailyTask:'日常任务',
    achTask:'成就任务',
    allReceive:'一键领取',
    progress:'进行中',
    receive:'领取',
    received:'已领取',
    myInvite:'我的邀请',
    lookDetail:'查看明细',
    successInvite:'成功邀请(人)',
    awarded:'已获得奖励',
    inviteFriend:'邀请好友',
    successCopy:'成功复制链接',
    inviteAddr:'邀请链接地址',
    congratulations:'恭喜您',
    successAward:'成功领取奖励',
    address:'地址',
    lockType:'锁仓类型',
    count:'数量',
};
export default zh;
