import { useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/rank.module.scss';
import { FormattedMessage } from 'react-intl';
import { removeDecimal } from '../utils';
import { scoreDecimal } from '../store/constants';

const formatList = (props) => {
    const { myRank, rankList } = props;
    const list = [];
    let gap = 0;
    if (myRank > 1) {
        gap = removeDecimal(
            rankList[myRank - 2].score - rankList[myRank - 1].score,
            scoreDecimal,
            1
        );
    }
    let sum1To3 = 0;
    let sum4To10 = 0;
    for (let i = 0; i < rankList.length; i++) {
        if (i < 3) {
            sum1To3 += Number(
                removeDecimal(rankList[i].score, scoreDecimal, 1)
            );
        } else {
            sum4To10 += Number(
                removeDecimal(rankList[i].score, scoreDecimal, 1)
            );
        }
    }
    sum1To3 = sum1To3.toFixed(1);
    sum4To10 = sum4To10.toFixed(1);
    for (let i = 0; i < rankList.length; i++) {
        let proportion = '不分红';
        if (rankList.length >= 10) {
            if (i < 3) {
                proportion =
                    (Number(removeDecimal(rankList[i].score, scoreDecimal, 1)) /
                        sum1To3) *
                    0.6;
            } else {
                proportion =
                    (Number(removeDecimal(rankList[i].score, scoreDecimal, 1)) /
                        sum4To10) *
                    0.3;
            }
            proportion = `${(proportion * 100).toFixed(2)}%`;
        }
        list.push({
            addr: rankList[i].player,
            score: removeDecimal(rankList[i].score, scoreDecimal, 1),
            proportion: proportion
        });
    }
    return { list, gap };
};
function Rank(props) {
    const { myGold, myRank, rankList } = props;
    const [viewMore, setViewMore] = useState(false);
    const lang = useSelector((state) => state.lang);
    let list = [];
    let gap = 0;
    if (rankList.length) {
        const data = formatList(props);
        list = data.list;
        gap = data.gap;
    }
    return (
        <div className={style.rank}>
            <div className={style.mainTitle}>
                <span>
                    <FormattedMessage id='elixirRanking' />
                </span>
                {myRank === 0 ? (
                    <div
                        className={style.progress}
                        style={{
                            marginTop: '28px'
                        }}>
                        <FormattedMessage id='myElixir' />: {myGold}
                    </div>
                ) : (
                    <div className={style.progress}>
                        <p className={style.currentRank}>
                            <FormattedMessage id='currentLevel' />: {myRank}
                        </p>
                        <p
                            className={`${style.progressLine} ${myRank === 1 ? style.top1 : ''
                                }`}>
                            <span></span>
                        </p>
                        {myRank === 1 ? (
                            <p className={style.gap}>
                                <FormattedMessage id='firstPlace' />
                            </p>
                        ) : lang === 'zh' ? (
                            <p className={style.gap}>{`距离第${myRank - 1
                                }名还差${gap}个仙丹`}</p>
                        ) : (
                            <p
                                className={
                                    style.gap
                                }>{`${gap} elixir away from ${myRank - 1
                                    } place`}</p>
                        )}
                    </div>
                )}
            </div>

            <div className={style.box}>
                <div className={`${style.title} ${style.singleRank} `}>
                    <span>
                        <FormattedMessage id='rank' />
                    </span>
                    <span>
                        <FormattedMessage id='myName' />
                    </span>
                    <span>
                        <FormattedMessage id='numberOfElixir' />
                    </span>
                    <span>
                        <FormattedMessage id='currentDividendRatio' />
                    </span>
                </div>
                {list.length &&
                    list.map((val, ix) => {
                        if (!viewMore && ix > 2) return null;
                        return (
                            <div
                                key={ix}
                                className={`${style.singleRank} ${ix === 2 && viewMore
                                        ? style.bottomBorder
                                        : ''
                                    }`}>
                                <span
                                    className={`${ix === 0 ? style.top1 : ''} ${ix === 1 ? style.top2 : ''
                                        } ${ix === 2 ? style.top3 : ''}`}>
                                    {ix > 2 ? ix + 1 : ''}
                                </span>
                                <span className={style.over}>{val.addr}</span>
                                <span>{val.score}</span>
                                <span>{val.proportion}</span>
                            </div>
                        );
                    })}
                {list.length > 3 ? (
                    <button
                        className={style.btn}
                        onClick={() => {
                            setViewMore(!viewMore);
                        }}>
                        {viewMore ? (
                            <FormattedMessage id='notViewMore' />
                        ) : (
                            <FormattedMessage id='viewMore' />
                        )}
                    </button>
                ) : null}
            </div>
        </div>
    );
}

export default Rank;
