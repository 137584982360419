import Popup from '../components/Popup';
import { FormattedMessage } from 'react-intl';

const Entry = (props) => {
    return (
        <Popup
            text={
                <>
                    <FormattedMessage id='wtf' /> <br />
                    <FormattedMessage id='jg' /> <br />
                    <FormattedMessage id='jg2' />
                </>
            }
            buttonText={<FormattedMessage id='ok' />}
            handleClick={() => props.history.push('/home')}
            minHeight='100vh'
            paddingTop='30vh'
        />
    );
};

export default Entry;
